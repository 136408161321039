<template>
  <div id="sign-up">
    <div style="width:400px;border:">
      <div class="align-center"><span class="m-f-color fs-40">Create Account</span></div>
      <div class="mt-50">
        <el-form ref="formInline" :model="formInline" :rules="ruleInline">
          <el-form-item prop="name">
              <el-input type="text" size="large" v-model="formInline.name" placeholder="Nickname">
              </el-input>
            </el-form-item>
            <el-form-item prop="email">
              <el-input type="text" size="large" v-model="formInline.email" placeholder="Email">
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password" size="large" v-model="formInline.password" placeholder="Password" >
              </el-input>
            </el-form-item>
            <el-form-item prop="confirm_password">
              <el-input type="password" size="large" v-model="formInline.confirm_password" placeholder="Password again">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="large" @click="handleSubmit('formInline')" class="w-p-100" :loading="common.signup_loading">Sign up</el-button>
            </el-form-item>
          </el-form>
          <div>
            <router-link to="/login">
              <span class="m-f-color fs-14 cursor-pointer">Sign in?</span>
            </router-link>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import {apiSignUp} from '@/api/user/login'


export default {
  name: "SignUp",
  data() {
    var validateSamePass = (rule, value, callback) => {
      if (this.formInline.confirm_password !== this.formInline.password) {
        callback(new Error('Passwords must match!'))
      } else {
        callback()
      }
    }
    /* 邮箱 */
    var emailFormatValid = (rule, value, callback) => {
      if (!value) {
        callback()
      } else {
        const reg =
          /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/
        const email = reg.test(value)
        if (!email) {
          callback(new Error("This email is invalid. Make sure it's written like example@email.com"))
        } else {
          callback()
        }
      }
    }
    return {
      formInline: {
        name: '',
        email: '',
        password: '',
        confirm_password: '',
      },
      ruleInline: {
        name: [
          {required: true, message: 'Enter your nickname', trigger: 'blur'},
        ],
        email: [
          {required: true, message: 'Enter your email', trigger: 'blur'},
          { validator: emailFormatValid, trigger: 'blur' }
        ],
        password: [
          {required: true, message: 'Enter your password', trigger: 'blur'},
          {type: 'string', min: 6, message: 'Password lengh must be over 6 numbers including letters', trigger: 'blur'},
          
        ],
        confirm_password:[
          { required: true, validator: validateSamePass, trigger: 'blur' },
          {required: true, message: 'Enter your password again', trigger: 'blur'},
          {type: 'string', min: 6, message: 'Confirm password lengh must be over 6 numbers including letters', trigger: 'blur'}
        ],
      },
      common: {
        signup_loading: false,
      }
    }
  },
  methods: {
    handleSubmit(name) {
      this.common.signup_loading = true;

      this.$refs[name].validate((valid) => {
        if (valid) {
          let param = {
            name: this.formInline.name,
            email: this.formInline.email,
            password: this.md5(this.formInline.password),
          }
          apiSignUp(param).then(res => {
            if (res.data.err_no == 0) {
              this.$message({
                message: "Sign Up Success!",
                type: 'success'
              });

              this.$router.push({name: 'login'});
              this.common.signup_loading = false;
            } else {
              this.$message({
                message: res.data.err_msg,
                type: 'error'
              });
            }

            this.common.signup_loading = false;
          })
        } 
        this.common.signup_loading = false;
      });
    }
  }
}
</script>

<style scoped>
#sign-up {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
</style>