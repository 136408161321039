import request from '@/plugins/http'

export function apiLogin(param) {
  return request.post('/api/user/login', param);
}

export function apiSignUp(param) {
  return request.post('/api/user/signUp', param);
}

